import { useSearchParams } from 'react-router-dom'
import { useAuthenticatedFetch } from './user-logged-in-fetch'
import { useCallback } from 'react'

export function useFasletService() {
  const basePath = '/api'

  const [searchParams] = useSearchParams()
  const host = searchParams.get('host') ?? undefined
  const shop = searchParams.get('shop') ?? undefined
  const hmac = searchParams.get('hmac') ?? undefined
  const idToken = searchParams.get('id_token') ?? undefined
  const authenticatedFetch = useAuthenticatedFetch()

  const authenticate = useCallback(async () => {
    const response = await authenticatedFetch(
      `${basePath}/auth?shop=${shop}&host=${host}&hmac=${hmac}&id_token=${idToken}`
    )

    if (response?.ok) {
      return { error: false }
    } else {
      return { error: response?.status, result: null }
    }
  }, [authenticatedFetch, shop, host, hmac, idToken])

  const getBillingInfo = useCallback(
    async (shopId: string) => {
      const response = await authenticatedFetch(`${basePath}/billing-info?shop=${shop}&shopId=${shopId}`)

      if (response?.ok) {
        const result = await response.json()
        return { error: false, result }
      } else {
        return { error: response?.status, result: null }
      }
    },
    [authenticatedFetch, shop]
  )

  const getShopId = useCallback(async () => {
    const response = await authenticatedFetch(`${basePath}/shop-id?shop=${shop}&host=${host}`, { method: 'GET' })

    if (response?.ok && response?.status === 200) {
      const result = await response.json()
      return { error: false, result }
    } else {
      return { error: response?.status, result: null }
    }
  }, [authenticatedFetch, shop, host])

  const setShopId = useCallback(
    async (shopId?: string) => {
      const response = await authenticatedFetch(
        `${basePath}/shop-id?shop=${shop}&shopId=${shopId ?? ''}&host=${host}`,
        {
          method: 'POST',
        }
      )
      if (response?.ok && response?.status === 200) {
        return { error: false, result: null }
      } else {
        return { error: response?.status, result: null }
      }
    },
    [authenticatedFetch, shop, host]
  )

  const getStatus = useCallback(async () => {
    const response = await authenticatedFetch(`${basePath}/status?shop=${shop}&host=${host}`)

    if (response?.ok) {
      const result = await response.json()
      return { error: false, result }
    } else {
      return { error: response?.status, result: null }
    }
  }, [authenticatedFetch, shop, host])

  const installPixel = useCallback(
    async (shopId?: string) => {
      const response = await authenticatedFetch(
        `${basePath}/pixel/install?shop=${shop}&host=${host}&shopId=${shopId}`,
        {
          method: 'POST',
        }
      )

      if (response?.ok) {
        const result = await response.json()
        return { error: false, result }
      } else {
        return { error: response?.status, result: null }
      }
    },
    [authenticatedFetch, shop, host]
  )

  const deletePixel = useCallback(async () => {
    const response = await authenticatedFetch(`${basePath}/pixel?shop=${shop}&host=${host}`, {
      method: 'DELETE',
    })

    if (response?.ok) {
      const result = await response.json()
      return { error: false, result }
    } else {
      return { error: response?.status, result: null }
    }
  }, [authenticatedFetch, shop, host])

  return {
    authenticate,
    getStatus,
    getBillingInfo,
    getShopId,
    setShopId,
    installPixel,
    deletePixel,
  }
}
