import { PropsWithChildren } from 'react'

interface RowProps {}

export function Row({ children, ...props }: PropsWithChildren<RowProps>) {
  return (
    <div className="row" {...props}>
      {children}
    </div>
  )
}

export function CenteredRow({ children, ...props }: PropsWithChildren<RowProps>) {
  return (
    <div className="row center-vertical" {...props}>
      {children}
    </div>
  )
}
