import { useAppBridge } from '@shopify/app-bridge-react'
import { Redirect } from '@shopify/app-bridge/actions'
import { useCallback } from 'react'

// Shopify doesn't give us this type :(
export function userLoggedInFetch(shopify: any) {
  return async (uri: string, options?: RequestInit) => {
    const response = await fetch(`${uri}`, options)

    if (response.redirected) {
      console.log(`redirect to ${response.url}`, response)
      const redirect = Redirect.create(shopify)
      redirect.dispatch(Redirect.Action.APP, response.url || `/api/auth`)
      return null
    }

    if (response.headers.get('X-Shopify-API-Request-Failure-Reauthorize') === '1') {
      console.log(`Shopify redirect to ${response.headers.get('location')}`)
      const authUrlHeader = response.headers.get('X-Shopify-API-Request-Failure-Reauthorize-Url')

      const redirect = Redirect.create(shopify)
      redirect.dispatch(Redirect.Action.APP, authUrlHeader || `/api/auth`)
      return null
    }

    return response
  }
}

export function useAuthenticatedFetch() {
  const shopify = useAppBridge()

  return useCallback(
    (uri: string, options?: RequestInit) => {
      return userLoggedInFetch(shopify)(uri, options)
    },
    [shopify]
  )
}
