import { Button, Card, Text } from '@shopify/polaris'
import { PricePlan } from '../types'
import { Spacer } from './spacer'
import { Row } from './row'
import en from '../translations/en.json'
import de from '../translations/de.json'
import { useI18n } from '@shopify/react-i18n'

interface BillingBlockProps {
  pricePlan: PricePlan
  activatePricePlan: () => unknown
}

export function BillingBlock({ pricePlan, activatePricePlan }: BillingBlockProps) {
  const [i18n] = useI18n({
    id: 'home',
    translations(locale) {
      console.log('got locale', locale)
      if (locale.startsWith('de')) {
        return de
      }
      return en
    },
  })

  return (
    <>
      {!pricePlan.name && (
        <Card background="bg-fill-critical-secondary">
          <Row>
            <Text variant="bodyMd" as="h4">
              {i18n.translate('billing.notFound')}
            </Text>
            <Spacer />
            <Button onClick={() => window.open('mailto:support@faslet.me', '_blank')}>
              {i18n.translate('billing.getInTouch')}
            </Button>
          </Row>
        </Card>
      )}
      {pricePlan.name && pricePlan.confirmationUrl && (
        <Card background="bg-fill-warning-secondary">
          <Row>
            <Text variant="bodyMd" as="h4">
              {i18n.translate('billing.notActive')}
            </Text>
            <Spacer />
            <Button onClick={activatePricePlan}>{i18n.translate('billing.activate')}</Button>
          </Row>
        </Card>
      )}
    </>
  )
}
