import { PropsWithChildren } from 'react'

interface GapProps {
  size?: number
}

export function Gap({ children, size, ...props }: PropsWithChildren<GapProps>) {
  return (
    <div className={`gap-${size ?? 2}`} {...props}>
      {children}
    </div>
  )
}
