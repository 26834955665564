import { useCallback } from 'react'
import { Modal } from '@shopify/polaris'

interface ImagePopupProps {
  image: string
  title: string
  alt: string
  open: boolean
  onClose: () => unknown
  children?: React.ReactNode
  info?: React.ReactNode
}

export function ImagePopup({ image, title, alt, open, onClose, info, children }: ImagePopupProps) {
  const onOpen = useCallback(() => {
    window.open(image, '_blank')
  }, [image])

  return (
    <Modal size="large" open={open} onClose={onClose} title={title}>
      <Modal.Section>
        {info}
        <div className="instruction-parent" onClick={onOpen}>
          <img className="instruction-gif" src={image} alt={alt} />
        </div>
        {children}
      </Modal.Section>
    </Modal>
  )
}
