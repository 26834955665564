import { Icon } from '@shopify/polaris'

const rawSvg = `<svg width="100%" height="100%" viewBox="-120 -120 452 707" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
<g transform="matrix(0.9,0,0,0.9,-266.054,-122.529)">
    <path id="F" d="M266.102,137.803C266.746,129.254 273.891,122.521 282.608,122.529L580.162,122.883C589.208,122.892 596.558,130.192 596.629,139.233L597.142,201.708C597.217,210.871 589.833,218.346 580.671,218.383L387.475,219.1L387.475,374.742L566.325,375.279C575.437,375.304 582.808,382.7 582.808,391.813L582.808,454.504C582.808,463.596 575.467,470.983 566.375,471.038L387.475,472.112L387.475,692.85C387.475,701.983 380.075,709.383 370.942,709.383L282.587,709.383C273.458,709.383 266.054,701.983 266.054,692.85L266.054,666.296L353.988,666.296L353.988,660.604L266.054,660.775L266.054,625.529L306.504,625.558L306.504,623.362L266.054,623.446L266.054,584.05L307.438,584.083L307.438,581.887L266.054,581.971L266.054,544.75L354.504,544.75L354.504,539.058L266.054,539.225L266.054,503.983L307.021,504.012L307.021,501.821L266.054,501.904L266.054,462.508L307.954,462.538L307.954,460.342L266.054,460.425L266.054,425.296L353.979,425.296L353.979,419.604L266.054,419.775L266.054,384.533L306.496,384.562L306.496,382.371L266.054,382.454L266.054,343.054L307.429,343.083L307.429,340.892L266.054,340.975L266.054,305.267L354.325,305.267L354.325,299.571L266.054,299.742L266.054,259.087L307.096,259.117L307.096,256.925L266.054,257.008L266.054,221.167L306.792,221.196L306.792,219.004L266.054,219.087L266.054,182.683L354.433,182.717L354.433,177.021L266.054,177.192L266.054,139.062C266.054,138.643 266.07,138.227 266.101,137.817L307.096,137.846L307.096,135.654L266.392,135.733L266.217,136.75L266.102,137.803ZM266.142,694.479L307.021,694.396L307.021,696.587L266.475,696.558L266.279,695.567L266.142,694.479Z" style="fill:rgb(79,174,146);fill-rule:nonzero;"/>
</g>
</svg>`

export function FasletIcon() {
  return <Icon source={rawSvg} tone="success" />
}
