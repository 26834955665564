import { useCallback, useEffect, useState } from 'react'
import { Button, Card, Link, Page, Text, TextField, Box } from '@shopify/polaris'
import { useSearchParams } from 'react-router-dom'

import { BillingBlock, Gap, Row, Spacer, FasletIcon } from '../../components'
import { PricePlan, Status } from '../../types'
import { AppBlockInstallation } from './app-block-installation'
import { useAppBridge, Modal, TitleBar } from '@shopify/app-bridge-react'
import { ConnectIcon as ConnectIcon, XIcon as DisconnectIcon, ComposeIcon as ReviewIcon } from '@shopify/polaris-icons'
import { useFasletService } from 'utils/faslet-service'
import { useI18n } from '@shopify/react-i18n'
import en from '../../translations/en.json'
import de from '../../translations/de.json'

export function Home() {
  const [i18n] = useI18n({
    id: 'home',
    translations(locale) {
      console.log('got locale', locale)
      if (locale.startsWith('de')) {
        return de
      }
      return en
    },
  })

  const shopify = useAppBridge()
  const [searchParams] = useSearchParams()
  const host = searchParams.get('host') ?? undefined
  const shop = searchParams.get('shop') ?? undefined

  const [localShopId, setLocalShopId] = useState<string | undefined>()
  const [validShopId, setValidShopId] = useState<boolean>(false)
  const [loading, setLoading] = useState(false)
  const [pricePlan, setPricePlan] = useState<PricePlan>({})
  const [status, setStatus] = useState<Status>({})

  const { getBillingInfo, getShopId, getStatus, setShopId, installPixel, deletePixel, authenticate } =
    useFasletService()

  const activatePricePlan = useCallback(async () => {
    window.open(pricePlan.confirmationUrl, '_top')
  }, [pricePlan])

  const queryPricePlan = useCallback(
    async (shopId?: string) => {
      const { error, result } = await getBillingInfo(shopId ?? '')
      if (!error) {
        setPricePlan(result)
      } else {
        setPricePlan({})
      }
    },
    [getBillingInfo, setPricePlan]
  )

  const queryStatus = useCallback(
    async (shopId?: string) => {
      if (!shop) {
        shopify.toast.show('Shop not found', { duration: 5000 })
        return
      }

      const { error, result } = await getShopId()

      if (!error) {
        setLocalShopId(result.shopId)
        setValidShopId(true)
        await queryPricePlan(result.shopId)
      } else if (shopId) {
        shopify.toast.show('Shop not found', { duration: 5000 })
        setValidShopId(false)
      } else {
        setValidShopId(false)
      }

      const { error: statusError, result: statusResult } = await getStatus()
      if (!statusError) {
        setStatus(statusResult)
      }
    },
    [host, shop, getStatus, queryPricePlan, getShopId, setValidShopId, setLocalShopId, shopify.toast]
  )

  const showConnectModal = useCallback(() => {
    shopify.modal.show('connect-modal')
  }, [shopify.modal])

  const hideConnectModal = useCallback(async () => {
    shopify.modal.hide('connect-modal')
  }, [shopify.modal])

  const saveShopId = useCallback(async () => {
    setLoading(true)
    await setShopId(localShopId)
    await queryStatus(localShopId)
    const { error } = await installPixel(localShopId)
    if (error) {
      shopify.toast.show(i18n.translate('orderTrackingErrorInstall'), { duration: 5000 })
    }
    await queryStatus(localShopId)
    setLoading(false)
    hideConnectModal()
  }, [shop, localShopId, setShopId, queryStatus, host, hideConnectModal, shopify.toast, i18n.translate])

  const disconnectShopId = useCallback(async () => {
    setLoading(true)
    setLocalShopId(undefined)
    await deletePixel()
    await setShopId()
    await queryStatus()
    setLoading(false)
  }, [shop, localShopId, setShopId, queryStatus, host, setLocalShopId, deletePixel])

  const onInstallPixel = useCallback(async () => {
    setLoading(true)
    const { error } = await installPixel(localShopId)
    if (error) {
      shopify.toast.show(i18n.translate('orderTrackingErrorInstall'), { duration: 5000 })
    }
    await queryStatus(localShopId)
    setLoading(false)
  }, [installPixel, setLoading, localShopId, shopify.toast, i18n.translate])

  const onDeletePixel = useCallback(async () => {
    setLoading(true)
    const { error } = await deletePixel()
    if (error) {
      shopify.toast.show(i18n.translate('orderTrackingErrorUninstall'), { duration: 5000 })
    }
    await queryStatus(localShopId)
    setLoading(false)
  }, [installPixel, setLoading, localShopId, shopify.toast, i18n.translate])

  const openSignupForm = useCallback(() => {
    window.open('https://portal.faslet.net/signup', '_blank')
  }, [])

  useEffect(() => {
    ;(async () => {
      setLoading(true)
      await authenticate()
      await queryStatus()
      setLoading(false)
    })()
  }, [shop, queryStatus, authenticate])

  if (!validShopId) {
    return (
      <Page title={i18n.translate('welcome')}>
        <Card>
          <Text variant="headingMd" as="h4">
            {i18n.translate('home.welcome')}
          </Text>
          <Gap />
          <Text variant="bodyMd" as="p">
            {i18n.translate('home.introText1')}{' '}
            <Link url="mailto:support@faslet.me" target="_blank">
              {i18n.translate('home.introText2')}
            </Link>
            .
            <br />
            {i18n.translate('home.introText3')}
          </Text>
          <Gap size={4} />
          <Text variant="bodyMd" as="h4" alignment="center">
            {i18n.translate('home.pricingStarts', { price: <strong>$199,00</strong> })}
          </Text>
          <Gap size={4} />
          <Row>
            <Spacer />
            <Button variant="primary" icon={ConnectIcon} size="large" onClick={showConnectModal} loading={loading}>
              {i18n.translate('home.connectStore')}
            </Button>
            <Gap />
            <Button size="large" icon={FasletIcon} onClick={openSignupForm} loading={loading}>
              {i18n.translate('home.signUp')}
            </Button>
            <Spacer />
          </Row>
        </Card>
        <Modal id="connect-modal">
          <Box padding="400">
            <p>{i18n.translate('home.enterShopId')}</p>
            <Gap />
            <TextField
              placeholder={i18n.translate('home.enterShopId')}
              value={localShopId}
              disabled={loading}
              onChange={setLocalShopId}
              autoComplete="off"
              label=""
            />
          </Box>
          <Gap />
          <TitleBar title={i18n.translate('home.connectStore')}>
            <button variant="primary" onClick={saveShopId} disabled={!localShopId || loading}>
              {i18n.translate('save')}
            </button>
            <button onClick={hideConnectModal} disabled={loading}>
              {i18n.translate('cancel')}
            </button>
          </TitleBar>
        </Modal>
      </Page>
    )
  }

  return (
    <Page
      title={`${i18n.translate('title')} - ${localShopId}`}
      secondaryActions={[
        {
          content: i18n.translate('home.openPartnerPortal'),
          onAction: () => window.open('https://portal.faslet.net', '_blank'),
          icon: FasletIcon,
        },
        {
          content: i18n.translate('home.disconnectStore'),
          onAction: disconnectShopId,
          destructive: true,
          icon: DisconnectIcon,
        },
      ]}
    >
      <Card>
        <Row>
          <Text variant="headingMd" as="h4">
            {i18n.translate('home.loggedInHeader')}
          </Text>
        </Row>
        <Gap />
        {status.appBlocksSupported ? (
          <AppBlockInstallation shop={shop} shopId={localShopId} status={status} pricePlan={pricePlan} />
        ) : (
          <Text variant="bodyMd" as="p" tone="caution">
            {i18n.translate('home.appBlocksNotSupported')}
          </Text>
        )}
        <Gap />
        <Row>
          <Text variant="bodyMd" as="h4">
            {i18n.translate('home.otPixel')}
          </Text>
          <Spacer />
          {status.orderTrackingPixel ? (
            <Button onClick={onDeletePixel} loading={loading}>
              {i18n.translate('home.uninstallOT')}
            </Button>
          ) : (
            <Button onClick={onInstallPixel} loading={loading}>
              {i18n.translate('home.installOT')}
            </Button>
          )}
        </Row>
        <Gap />
        <Row>
          <Text variant="bodyMd" as="h4">
            {i18n.translate('home.review')}
          </Text>
          <Spacer />
          <Button
            onClick={() => window.open('https://apps.shopify.com/faslet-size-me-up/reviews', '_blank')}
            icon={ReviewIcon}
          >
            {i18n.translate('home.review1')}
          </Button>
        </Row>
        <Gap />
        {(!status.isDevelopmentStore || localShopId === 'faslet-demo') && !!pricePlan?.name && (
          <>
            <BillingBlock pricePlan={pricePlan} activatePricePlan={activatePricePlan} />
            <Gap />
          </>
        )}
      </Card>
    </Page>
  )
}
