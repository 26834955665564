import { PropsWithChildren } from 'react'

interface SpacerProps {}

export function Spacer({ children, ...props }: PropsWithChildren<SpacerProps>) {
  return (
    <div className="spacer" {...props}>
      {children}
    </div>
  )
}
