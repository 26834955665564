import { useSearchParams } from 'react-router-dom'

import { Home } from './pages'

export function FasletApp() {
  const [searchParams] = useSearchParams()
  const host = searchParams.get('host')

  if (!host) {
    return <p>Host not found</p>
  }

  return (
    <>
      <Home />
    </>
  )
}
